<template>
  <div class="edu-frame">
    <edu-nav-bar title="班级报名情况" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <van-dropdown-menu :close-on-click-outside="false">
      <van-dropdown-item title="搜索" ref="item" icon="search">
        <van-field v-model="tablePage.condition.fullName" label="学员名称" placeholder="请输入学员编号或名称" input-align="right" clearable/>
        <van-field v-model="tablePage.condition.className" label="课程名称" placeholder="请输入课程名称" input-align="right" clearable/>
        <van-field v-model="tablePage.condition.academicTerm" label="学期" placeholder="请选择学期" input-align="right" clearable is-link readonly @click="showTermsetups=true"/>
        <div style="padding: 5px 16px;">
          <van-button type="info" block round @click="onSearch">搜索</van-button>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>
    <van-list class="content-frame" style="height: calc(100vh - 46px - 48px)" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getBaomingQingKuang">
      <div v-for="(clazz, index) in classes" :key="index" class="edu-group class-item">
        <div class="class-detail">
          <div class="class-detail-title">班级名称：{{ clazz.className }}</div>
          <div class="class-detail-row">
            <div class="class-detail-row-title">学期：{{clazz.academicTerm}}</div>
          </div>
          <div class="class-detail-row">
            <div class="class-detail-row-title">注册时间：{{clazz.regDate | filterDate }}</div>
          </div>
          <div class="class-detail-row">
            <div class="class-detail-row-title">学员编号：{{clazz.custCode}}</div>
            <div class="class-detail-row-title">学员名称：{{ clazz.fullName }}</div>
          </div>
          <div class="class-detail-row">
            <div class="class-detail-row-title">状态：{{ clazz.isReg == 1 ? "已注册" : '未注册' }}</div>
            <div class="class-detail-row-title">学费：{{ clazz.rcMoney }}元</div>
          </div>
          <div class="class-detail-row">
            <div class="class-detail-row-title">退费状态：{{ clazz.isRefund == 0 ? "未退费" : clazz.isRefund == 1 ? '已退费' : '退费中' }}</div>
            <div class="class-detail-row-title">退费金额：{{ clazz.refundMoney == null ? 0 : clazz.refundMoney }}元</div>
          </div>
        </div>
      </div>
    </van-list>

    <van-popup v-model="showTermsetups" position="bottom" :close-on-click-overlay="false">
      <van-picker show-toolbar :columns="termSetupOpts" @confirm="selectTermsetup" @cancel="showTermsetups=false;"/>
    </van-popup>
  </div>
</template>
<script>
import {Button, DropdownMenu, DropdownItem, List, Field, Popup, Picker} from "vant";
import EduNavBar from "@/components/EduNavBar";
import BaomingApi from "@/api/BaomingApi";
import XEUtils from "xe-utils";
import Tools from "@/api/Tools";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanDropdownMenu: DropdownMenu,
    VanDropdownItem: DropdownItem,
    VanList: List,
    VanField: Field,
    VanPopup: Popup,
    VanPicker: Picker
  },
  filters: {
    filterDate(date) {
      return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  data(){
    return {
      cust: Tools.getCurCust(),
      show: false,
      tablePage: {page: 1, pageSize: 10, condition: {}},
      loading: false,
      finished: false,
      classes: [],
      showTermsetups: false,
      termSetupOpts: []
    }
  },
  methods: {
    selectTermsetup(e) {
      console.log('selectTermsetup..', e)
      this.tablePage.condition.termId = e.termId
      this.tablePage.condition.academicTerm = e.academicTerm
      this.showTermsetups = false
    },
    onSearch() {
      // console.log(this.tablePage.condition.className)
      this.classes = []
      this.tablePage.page = 1
      this.tablePage.condition.isShowNotFull = this.tablePage.condition.showNotFull ? '1' : null
      // console.log(this.tablePage.condition.isShowNotFull)
      this.getBaomingQingKuang()
      this.$refs.item.toggle();
    },
    getBaomingQingKuang() {
      this.loading = true
      this.tablePage.condition.teacherId = this.cust.custId
      BaomingApi.getBaoMingQingKuang(this.tablePage).then(response => {
        this.loading = false
        for (var i = 0; i < response.res.list.length; i++) {
          this.classes.push(response.res.list[i])
        }
        console.log('this.classes == ', this.classes)
        if (response.res.isLastPage==true) {
          this.finished = true
        } else {
          this.tablePage.page = response.res.pageNum + 1
        }
      }).catch(()=>{
        this.loading = false
        this.finished = true
      })
    },
    listTermsetups() {
      BaomingApi.getTermsetups({}).then(response => {
        for (var i = 0; i < response.res.length; i++) {
          response.res[i].text = response.res[i].academicTerm
          response.res[i].value = response.res[i].termId
        }
        this.termSetupOpts = response.res
      })
    }
  },
  mounted() {
    this.listTermsetups()
  }
}
</script>
<style scoped>

</style>
